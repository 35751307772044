<template>
	<select
		:class="[
			'appearance-none rounded-full border border-zinc-500 bg-white bg-no-repeat px-[10px]',
			`text-[length:var(--font-size-mobile)] md:text-[length:var(--font-size)]`,
			'focus-visible:rounded-full focus-visible:ring-1 focus-visible:ring-cta',
		]"
		:style="[backgroundStyle, cssProps]"
		:aria-label="aria"
		@change="emit('changeOption', ($event.target as HTMLSelectElement).selectedIndex)"
	>
		<option v-for="option in normalizedOptions" :key="option.key" :value="option.value">
			{{ option.text }}
		</option>
	</select>
</template>

<script setup lang="ts">
import type { StyleValue } from 'vue';

interface OptionObject {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

interface NormalizedOption {
	key: string | number;
	value: string | number;
	text: string;
}

interface Props {
	options: OptionObject[] | OptionObject;
	keyProperty?: string;
	valueProperty?: string;
	textProperty?: string;
	width?: string;
	height?: string;
	fontSize?: string;
	fontSizeMobile?: string;
	aria: string;
}

const props = withDefaults(defineProps<Props>(), {
	keyProperty: 'id',
	valueProperty: 'value',
	textProperty: 'text',
	width: '100%',
	height: '40px',
	fontSize: '1rem',
	fontSizeMobile: '1rem',
});

const emit = defineEmits<{
	(eventName: 'changeOption', value: number): void;
}>();

const backgroundStyle = computed<StyleValue>(() => ({
	width: props.width,
	height: props.height,
	backgroundImage:
		'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyLDE1Yy0uMjEsMC0uNDEtLjA5LS41NS0uMjRsLTMuMjUtMy41Yy0uMjgtLjMtLjI2LS43OCwuMDQtMS4wNiwuMy0uMjgsLjc4LS4yNiwxLjA2LC4wNGwyLjcsMi45MSwyLjctMi45MWMuMjgtLjMsLjc2LS4zMiwxLjA2LS4wNCwuMywuMjgsLjMyLC43NiwuMDQsMS4wNmwtMy4yNSwzLjVjLS4xNCwuMTUtLjM0LC4yNC0uNTUsLjI0WiIvPjwvc3ZnPg==")',
	backgroundSize: '28px',
	backgroundPosition: '100% 50%',
	backgroundRepeat: 'no-repeat',
	mixBlendMode: 'darken',
}));

const cssProps = computed(() => {
	return {
		'--font-size': props.fontSize,
		'--font-size-mobile': props.fontSizeMobile,
	};
});

const normalizedOptions = computed<NormalizedOption[]>(() => {
	// If it's a single object, wrap it in an array
	const optionsArray = Array.isArray(props.options) ? props.options : [props.options];

	return optionsArray.map((option, i) => {
		if (typeof option === 'object' && option !== null) {
			return {
				key: option[props.keyProperty] || i,
				value: option[props.valueProperty] || i,
				text: option[props.textProperty] || '',
			};
		} else {
			return {
				key: i,
				value: i,
				text: String(option),
			};
		}
	});
});
</script>
